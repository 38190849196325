/*BRADS CSS---------------*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  height: 100vh;
  color: rgb(255, 255, 255) !important;
}
::-webkit-scrollbar {
  display: none;
}

p {
  line-height: 1.7;
  color: hsl(0, 0%, 35%);
  font-size: 1.1vw;
}

li {
  line-height: 2.2;
}

h1,
h2,
h3 {
  font-weight: 600;
  margin-bottom: 10px;
}

.container {
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}

#headerInnerContainer {
  max-width: none !important;
  margin-left: 40px;
  margin-right: 40px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e6e6e6;
}

.header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header ul li {
  margin-left: 20px;
}

.header ul li a {
  display: flex;
  align-items: center;
}

.header ul li a:hover {
  color: #777;
}

.header ul li a svg {
  margin-right: 5px;
}

.heading {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 50px;
  padding: 0 20px;
  text-transform: uppercase;
  z-index: 10;
}

.btns {
  padding: 10px 20px;
  border: 1px solid rgba(250, 250, 250, 0.545);
  border-radius: 5px;
  background: rgb(26, 108, 112);
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btns:hover {
  transform: scale(0.98);
  background: rgb(61, 132, 136);
}

/*MY CSS---------------*/
/*GENERAL------------------------*/
.alignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
a {
  text-decoration: none !important ;
  color: rgb(255, 255, 255) !important;
}

.icon {
  font-size: 50px;
  color: rgba(255, 255, 255, 0.708);
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  vertical-align: bottom;
}

.icon:hover {
  color: rgb(255, 255, 255);
  transform: scale(0.95);
}

.icon-sm {
  font-size: 2vw;
}

.icon-xs {
  font-size: 1.4vw;
}

/*NAVBAR---------------*/
.headerContainer {
  position: fixed !important;
  width: 100vw;
  background-color: #2f3c4f;
  height: 12vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 3px solid #fafafa;
  z-index: 11;
}

.img-logo {
  max-width: 50%;
  height: auto;
  transition: 0.2s all ease-in-out;
  filter: none;
}

.img-logo:hover {
  transform: scale(0.95);
  filter: grayscale(100%);
}

.menuItems {
  border-right: 1px solid white;
  border-left: 1px solid white;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: space-around;
}

.menuItems a {
  font-weight: 100;
  font-size: 12px;
}

a:hover {
  color: rgb(118, 118, 118) !important;
}

.navbar {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.navbar-toggler {
  /* background-color: #fafafa !important; */
  border: 1px solid #fafafa !important;
}

.navbar-toggler-icon {
  color: #fafafa !important;
}
.navbar > .container-fluid {
  display: inline-block !important;
}

/*MAIN PAGE SECTION*/
.pageContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 70px; */
}

/*HOME PAGE*/
.homeContainer {
  position: absolute;
  opacity: 0.3;
  background: url(../public/assets/overlay3.png) !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100vw;
  height: 88vh;
  bottom: 0;
}

#refl {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  -moz-transform: rotateX(210deg);
  -o-transform: rotateX(210deg);
  -webkit-transform: rotateX(210deg);
  transform: rotateX(210deg);
  perspective: 200px;
  -webkit-mask-image: -webkit-gradient(
    linear,
    right top,
    right bottom,
    from(transparent),
    color-stop(20%, transparent),
    to(rgba(0, 0, 0, 0.4))
  );
}

/*ABOUT ME------------*/
.profileImgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileImgContainer img {
  max-width: 80%;
  min-width: 200px;
  border-width: 7px 5px 5px 7px;
  border-radius: 95% 4% 97% 5%/4% 94% 3% 95%;
  border: solid rgb(255, 255, 255);
  filter: opacity(0.9), grayscale(100%);

  /* mask-image: radial-gradient(ellipse 90% 80% at 48% 78%, black 40%, transparent 50%);
  filter: opacity(0.7) grayscale(0.4) brightness(0.9)
    drop-shadow(29px 36px 23px #26364a); */
  /* border-radius: 60%;  */
}

.profileTextContainer {
  padding-left: none !important;
  padding-right: none !important;
  background-color: rgba(32, 113, 78, 0.251);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  border-width: 15px 11px 11px 15px;
  border-radius: 95% 4% 5% 5%/2% 54% 3% 1%;
  border: solid rgb(255, 255, 255);
}
.profileTextContainer p {
  color: #fff;
  font-size: 0.8vw;
}

/* My Works------------- */

.projectsContainer {
  height: 100%;
  padding-top: 18vh;
  padding-bottom: 5vh;
}

.projectContainer {
  margin-bottom: 15vh;

  border: solid #457896 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.img-col {
  justify-content: center;
  align-items: center;
  display: flex;
  border-right: 1px solid rgba(245, 245, 245, 0.496);
  z-index: 10;
}

.img-col-2 {
  justify-content: center;
  align-items: center;
  display: flex;
  border-left: 1px solid rgba(245, 245, 245, 0.496);
  z-index: 10;
}

.img-desktop {
  max-width: 100%;
  height: auto;
}

.img-desktop-2 {
  max-width: 100%;
  height: auto;
}

.myworksContainer {
  position: fixed;
  opacity: 0.7;
  background: url(../public/assets/overlay3.png) !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: repeat !important;
  width: 100vw;
  height: 88vh;
  bottom: 0;
}

.desc-col {
  z-index: 10;
}

.descList {
  font-size: 1.5vw;
}

.descList span {
  font-size: 1.7vw;
  color: lightgrey;
}
.projectHeading {
  mix-blend-mode: multiply;
  text-shadow: 5px 4px 11px rgb(255, 255, 255), 0 2px 4px rgb(0, 0, 0);
  text-decoration: wavy;
}

/* Skills ------------- */

.skillsContainer {
  height: 100%;
  padding-top: 18vh;
  padding-bottom: 5vh;
  min-height: 100vh;
}

.skillsSecondHeading {
  font-size: 1vw;
  margin-bottom: 50px;
  font-style: italic;
  color: #26364a6c;
}

#stacked-example-4 {
  height: 300px;
  max-width: 300px;
  font-size: 10px;
}

.skillStackCol {
  border-left: 2px dashed rgba(255, 255, 255, 0.6) !important;
  border-right: 2px dashed rgba(255, 255, 255, 0.6) !important;
  border-top: 2px dashed rgba(255, 255, 255, 0.6) !important;
  background: url(../public/assets/overlay2.png) !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  padding-top: 20px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.skillStackRow {
  border-right: 2px dashed rgba(255, 255, 255, 0.6) !important;
  border-top: 2px dashed rgba(255, 255, 255, 0.6) !important;
  border-bottom: 2px dashed rgba(255, 255, 255, 0.6) !important;
  background: url(../public/assets/overlay2.png) !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-right: 20px !important;
}

.charts-css.show-heading caption {
  color: #dfdfdf;
  font-size: 1.3vw;
  text-transform: uppercase;
  display: flex !important;
  justify-content: center;
}

.charts-css.column:not(.reverse) tbody tr th {
  font-weight: 200;
  margin-top: 25px !important;
}

tbody tr th {
  margin-top: 25px !important;
}

/* Column Sizes */
.size-20 {
  --size: calc(20 / 100);
}
.size-30 {
  --size: calc(30 / 100);
}
.size-40 {
  --size: calc(40 / 100);
}
.size-50 {
  --size: calc(50 / 100);
}
.size-60 {
  --size: calc(60 / 100);
}
.size-70 {
  --size: calc(70 / 100);
}
.size-80 {
  --size: calc(80 / 100);
}
.size-90 {
  --size: calc(90 / 100);
}

/* Contact Page ------------- */

.formContainer {
  padding: 25px;
  border-radius: 95% 4% 97% 5%/4% 94% 3% 95%;
  border: solid rgb(255, 255, 255);
  filter: opacity(0.9), grayscale(100%);
  max-width: 500px !important;
  background-color: rgba(115, 190, 117, 0.367);
}

.form,
.content {
  width: 80%;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

.form-group label {
  text-align: left;
  display: block;
  margin: 0 0 5px 3px;
}

.contactImg {
  max-width: 100%;
  mask-image: linear-gradient(
    to left,
    transparent 0%,
    transparent 4%,
    rgb(0, 0, 0) 25%,
    black 75%,
    transparent 96%,
    transparent 100%
  );

  filter: opacity(0.7) grayscale(0.4) brightness(0.9) hue-rotate(280deg)
    drop-shadow(29px 36px 23px #26364a);
  border-radius: 40%;
}

@media (max-width: 990px) {
  /*GENERAL*/
  p {
    font-size: 1.5vw;
    color: #fafafa;
  }

  .icon {
    font-size: 30px;
  }

  .icon-sm {
    font-size: 4vw;
  }

  .icon-xs {
    font-size: 1.8vw;
  }
  .heading {
    font-size: 40px;
  }

  /*ABOUT ME PAGE*/
  .profileImgContainer {
    margin-bottom: 40px;
  }

  .profileImgContainer img {
    max-width: 60%;
    min-width: none;
  }

  .profileTextContainer p {
    font-size: 1.4vw;
  }
  /* ProjectPage */

  .projectContainer {
    margin-bottom: 15vh;
    border: solid #457896 5px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }

  .img-desktop {
    max-width: 70%;
    border-bottom: 1px solid rgba(245, 245, 245, 0.496);
    padding-bottom: 15px;
  }
  .img-desktop-2 {
    max-width: 70%;
    border-top: 1px solid rgba(245, 245, 245, 0.496);
    margin-top: 15px;
    padding-top: 15px;
  }

  .img-col {
    border-right: none;
  }
  .img-col-2 {
    border-left: none;
  }

  .myworksContainer {
    position: fixed;
    opacity: 0.7;
    background: url(../public/assets/overlay3.png) !important;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: repeat !important;
    width: 100vw;
    height: 88vh;
    bottom: 0;
  }

  .desc-col {
    z-index: 10;
  }

  .projectHeading {
    mix-blend-mode: multiply;
    text-shadow: 5px 4px 11px rgb(255, 255, 255), 0 2px 4px rgb(0, 0, 0);
    text-decoration: wavy;
  }
  /*SKILLS PAGE*/
  #stacked-example-4 {
    margin-bottom: 60px;
  }

  /*CONTACT*/

  .form .content {
    width: 90%;
  }
}

@media (max-width: 767px) {
  .navbar > .container-fluid {
    display: flex;
  }

  .headerContainer {
    padding: 25px;
  }

  .menuItems {
    position: absolute;
    top: 12vh;
    left: 0;
    border-right: none;
    border-left: none;
    margin-left: 0;
    margin-right: 0;
    background-color: #48566b;
    justify-content: center;
    width: 100vw;
  }

  .navbar-nav {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    margin-left: 20px !important ;
    text-transform: uppercase;
  }

  .img-logo {
    max-width: 90%;
  }

  .profileTextContainer p {
    font-size: 1.5vw;
  }

  .skillsSecondHeading {
    font-size: 2vw;
  }

  .charts-css.show-heading caption {
    font-size: 2.3vw;
  }
}

@media (max-width: 460px) {
  /*GENERAL*/
  p {
    font-size: 2.5vw;
  }
  .icon {
    font-size: 25px;
  }

  .icon-sm {
    font-size: 4vw;
  }

  .icon-xs {
    font-size: 2vw;
  }
  .img-desktop {
    max-width: 90%;
  }
  .img-desktop-2 {
    max-width: 90%;
  }

  .profileTextContainer p {
    font-size: 2.5vw;
  }
}

/*LOGO ANIMATIONS HOMEPAGE */
#htmlLogo {
  animation: color 7s linear 0s infinite alternate;
}

#mongoLogo {
  animation: color 7s linear 1s infinite alternate;
}

#cssLogo {
  animation: color 7s linear 2s infinite alternate;
}

#reduxLogo {
  animation: color 7s linear 3s infinite alternate;
}

#jsLogo {
  animation: color 7s linear 4s infinite alternate;
}

#expressLogo {
  animation: color 7s linear 5s infinite alternate;
}

#reactLogo {
  animation: color 7s linear 6s infinite alternate;
}

#wixLogo {
  animation: color 7s linear 7s infinite alternate;
}

#pythonLogo {
  animation: color 7s linear 8s infinite alternate;
}

#wpLogo {
  animation: color 7s linear 9s infinite alternate;
}

#djLogo {
  animation: color 7s linear 10s infinite alternate;
}

/* Column Colors */
#homePageBlue {
  background-color: rgb(0, 71, 115) !important;
}
#green {
  background-color: rgba(35, 172, 87, 0.6) !important;
}

#blue {
  background-color: rgba(0, 183, 255, 0.6) !important;
}

#darkblue {
  background-color: rgba(8, 73, 154, 0.6) !important;
}

#yellow {
  background-color: rgba(255, 200, 0, 0.6) !important;
}

#yellow2 {
  background-color: rgba(255, 249, 84, 0.688) !important;
}

#red {
  background-color: rgba(255, 60, 0, 0.6) !important;
}

#purple {
  background-color: rgba(158, 71, 224, 0.6) !important;
}
#purpleBlue {
  background-color: rgba(71, 74, 224, 0.6) !important;
}

#pink {
  background-color: rgba(241, 20, 248, 0.6) !important;
}

#orange {
  background-color: rgba(255, 102, 0, 0.6) !important;
}

#black {
  background-color: rgba(41, 41, 41, 0.6) !important;
}

@keyframes color {
  0% {
    color: rgba(255, 255, 255, 0.707);
    transform: scale(1);
  }
  33% {
    color: rgb(255, 255, 255);
    transform: scale(1);
  }
  66% {
    color: rgba(255, 255, 255, 0.149);
    transform: scale(0.5);
  }
  100% {
    color: rgb(255, 255, 255);
    transform: scale(1);
  }
}
